import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomPagesService } from '@features/custom-pages';

import { NewsletterComponent } from './components/newsletter/newsletter.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterModule, NewsletterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  #pages = inject(CustomPagesService);

  protected navigation = computed(() => this.#pages.navigation().footer_items);
}
