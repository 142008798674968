<section class="bg-repeat bg-cover bongos-background" aria-label="Subscribe to the new events mailing list">
  <form [formGroup]="form" (ngSubmit)="subscribeToNewsletter()">
    <fieldset [disabled]="isBusy()">
      <div class="px-5 py-8 md:px-36 w-full lg:max-w-screen-2xl mx-auto">

        <div class="flex flex-col items-stretch max-w-screen-md mx-auto gap-4">
          <h2 class="text-3xl font-black text-center text-white">Join our newsletter</h2>

          <div
            class="flex flex-col xl:flex-row flex-wrap xl:flex-nowrap justify-center items-stretch lg:items-start gap-4">
            <div class="flex-1">
              <input type="email" class="input w-full" formControlName="email" placeholder="Your email"
                id="newsletter-email-input" />

              <app-form-validation-errors [control]="form.controls['email']"
                [validationErrors]="{ required: 'Email is required', email: 'Email must be a valid email address' }"></app-form-validation-errors>
            </div>


            <button class="white-button px-4 rounded-md flex items-center justify-center h-12"
              [disabled]="form.invalid || isBusy()">

              <span class="px-4 font-bold">Subscribe to mailing list</span>

            </button>


          </div>
          @if (isSubscribed()) {
          <app-static-notification type="success">
            <i class="icon fa-regular fa-check-circle text-2xl"></i>
            <p>You have been subscribed to the newsletter</p>
          </app-static-notification>
          }
        </div>
      </div>
    </fieldset>
  </form>

  <div class="flex justify-center px-5 w-full max-w-screen-2xl mx-auto md:w-1/2">
    <a class="focus:outline-none transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      href="https://www.facebook.com/BongosBingo/" target="_blank" aria-label="Share on Facebook" tabindex="0">
      <img src="assets/icons/facebook.svg" alt="Facebook" class="bg-primary" />
    </a>

    <a class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      href="https://www.instagram.com/bongosbingo/" target="_blank" aria-label="Share on Instagram" tabindex="1">
      <img src="assets/icons/instagram.svg" alt="Instagram" class="bg-primary" />
    </a>

    <a class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      href="mailto:?subject=Check this out&body=Check this out - https://bongosbingo.co.uk/" target="_blank"
      aria-label="Share via Email" tabindex="2">
      <img src="assets/icons/email.svg" alt="Email" class="bg-primary" />
    </a>

    <a class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      href="https://www.youtube.com/channel/UCHlz4LI1eZXuskb7OPt-2wQ" target="_blank" aria-label="Share on YouTube"
      tabindex="3">
      <img src="assets/icons/youtube.svg" alt="Youtube" class="bg-primary" />
    </a>

    <a class="focus:outline-none bg-white transform hover:scale-110 transition-all duration-300 rounded-full mx-2 flex items-center justify-center"
      href="https://www.tiktok.com/@bongosbingoofficial" target="_blank" aria-label="Share on TikTok" tabindex="4">
      <img src="assets/icons/tiktok.svg" alt="TikTok" class="bg-primary" />
    </a>
  </div>
</section>