<header
  class="bg-primary h-masthead-mobile md:min-h-masthead-desktop md:h-auto px-4 md:px-10 flex flex-row items-center md:gap-16"
  role="banner">
  <div class="flex-1 md:flex-none">
    <a [routerLink]="['/']" aria-label="Go to Bongo's Bingo homepage">
      <img src="assets/images/logo.svg" class="h-11" alt="" />
    </a>
  </div>

  <nav class="hidden 2xl:flex flex-row gap-12 items-center text-lg [&_a]:font-bold text-white flex-1">
    <div class="flex flex-row flex-wrap items-center flex-1 gap-x-12">
      <a [routerLink]="['/events']" title="Search for an event" class="uppercase">Events</a>

      @for(item of navigation(); track $index) {
      <div class="flex flex-row items-center gap-4">
        <a [routerLink]="['/pages', item.slug]" [title]="item.title" class="uppercase">{{ item.title }}</a>
        @if(item.children.length) {
        <button [cdkMenuTriggerFor]="overlay" #trigger="cdkMenuTriggerFor"
          [cdkMenuPosition]="[{originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'}]">
          <i class="fa-solid fa-chevron-down transition-transform duration-200"
            [class.rotate-180]="trigger.isOpen()"></i>
        </button>

        <ng-template #overlay>
          <div cdkMenu class="bg-primary text-white shadow-xl rounded-lg p-4 flex flex-col gap-2 font-bold min-w-48">
            @for(child of item.children; track $index) {
            <a [routerLink]="['/pages', child.slug]" [title]="child.title" (click)="trigger.close()">
              {{ child.title }}
            </a>
            }
          </div>
        </ng-template>

        }
      </div>
      }

    </div>

    <div class="flex flex-row items-center gap-4">
      @if (auth.authenticated()) {
      <a [routerLink]="['/profile']" class="flex gap-2 outline-button secondary" aria-label="Go to your profile page"><i
          class="fa-solid fa-circle-user"></i>
        <span>Profile</span>
      </a>

      <a [routerLink]="['/checkout']" class="filled-button secondary flex gap-2" aria-label="Go to checkout">
        <i class="fa-solid fa-cart-shopping"></i>
        <span>Basket</span>
      </a>
      } @else {
      <a [routerLink]="['/login']" [queryParams]="{ redirect: redirectUrl() }"
        class="outline-button secondary active:bg-buttons-primary-active" aria-label="Login to Bongo's Bingo">Login</a>
      <a [routerLink]="['/register']" class="filled-button secondary"
        aria-label="Register a new Bongo's Bingo account">Register</a>
      }
    </div>
  </nav>

  <div class="2xl:hidden absolute right-3">
    <button class="icon-button focus:outline-none bg-page-background aspect-square w-11 rounded-lg p-0"
      (click)="openDrawer()" aria-label="Open the navigation menu">
      <img src="assets/images/menu.svg" alt="" />
    </button>
  </div>
</header>