import { DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { filter, timer } from 'rxjs';

import { AuthService } from '@features/auth';
import { BasketService } from '@features/checkout';
import { CustomPagesService } from '@features/custom-pages';

import { slideInOutAnimation } from '../animations';
import { LogoutButtonComponent } from '../logout-button';

@Component({
  selector: 'app-navigation-drawer',
  standalone: true,
  imports: [RouterLink, LogoutButtonComponent],
  templateUrl: './navigation-drawer.component.html',
  styleUrl: './navigation-drawer.component.scss',
  animations: [slideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerComponent implements OnInit, AfterViewInit {
  #dialogRef = inject(DialogRef);
  protected auth = inject(AuthService);
  protected basket = inject(BasketService);

  #router = inject(Router);
  #pages = inject(CustomPagesService);

  protected navigation = computed(() => this.#pages.navigation().menu_items);
  protected animationState = signal<string>('out');
  protected redirectUrl = signal('/');

  ngOnInit() {
    this.redirectUrl.set(this.#router.url);

    this.#router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.closeDialog();
    });
  }

  ngAfterViewInit() {
    this.animationState.set('in');
  }

  protected closeDialog() {
    this.animationState.set('out');
    timer(200).subscribe(() => {
      this.#dialogRef.close();
      window.scrollTo(0, 0);
    });
  }
}
