<div [@slideInOut]="animationState()" class="w-full h-full bg-black text-white flex flex-col p-3">
  <div class="flex flex-col">
    <header class="relative mt-3">
      <div class="flex justify-center">
        <a [routerLink]="['/']" aria-label="Go to the Bongo's Bingo homepage">
          <img src="assets/images/logo.svg" class="w-40" alt="" />
        </a>
      </div>

      <button type="button" (click)="closeDialog()" class="absolute top-0 right-0 px-2 h-full"
        aria-label="Close navigation menu">
        <i class="fa-solid fa-xmark text-3xl"></i>
      </button>
    </header>

    <nav class="flex flex-col">
      <div
        class="w-full pt-7 flex flex-col divide-y divide-neutral-700 border-b border-b-neutral-700 no-underline text-lg font-bold [&_>a]:uppercase">
        <a class="pl-2.5 py-5" [routerLink]="['/', 'events']" title="Search for an event">Events</a>

        @for(item of navigation(); track $index) {
        <a class="pl-2.5 py-5" [routerLink]="['/', 'pages', item.slug]" [title]="item.title">{{ item.title }}</a>
        }
      </div>

      <div class="flex flex-col items-start gap-4 pt-9">
        @if (auth.authenticated()) {
        <div class="w-full">
          <a [routerLink]="['/', 'profile']" aria-label="Visit your profile page"
            class="flex outline-button secondary">Profile</a>
        </div>
        <div class="w-full">
          <app-logout-button label="Sign Out" color="flex outline-button secondary"
            icon="fa-sign-out"></app-logout-button>
        </div>
        <div class="w-full">
          <a [routerLink]="['/', 'checkout']" class="flex primary-button" aria-label="Go to checkout">
            <i class="fa-solid fa-cart-shopping mr-2"></i>
            <span>Basket</span>
          </a>
        </div>
        } @else {
        <div class="w-full flex space-x-2">
          <a [routerLink]="['/', 'login']" [queryParams]="{ redirect: this.redirectUrl }"
            aria-label="Login to Bongo's Bingo" class="flex-1 primary-button">Login</a>
          <a [routerLink]="['/', 'register']" aria-label="Register a new Bongo's Bingo account"
            class="flex-1 primary-button">Register</a>
        </div>
        }
      </div>
    </nav>
  </div>
</div>