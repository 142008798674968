import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize } from 'rxjs';

import { emailValidator } from '@features/common/validators';
import { FormValidationErrorsComponent } from '@components/form-fields';
import { StaticNotificationComponent } from '@components/static-notification';
import { SnackBar } from '@components/snack-bar';

import { FooterService } from '../../services/footer.service';

export interface NewsletterForm {
  email: FormControl<string>;
}

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormValidationErrorsComponent, StaticNotificationComponent],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterComponent {
  #footerService = inject(FooterService);

  #snackBar = inject(SnackBar);

  #destroyRef = inject(DestroyRef);

  #fb = inject(FormBuilder);

  protected form = this.#fb.nonNullable.group<NewsletterForm>({
    email: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.email, emailValidator()]),
  });

  protected isBusy = signal<boolean>(false);
  protected isSubscribed = signal<boolean>(false);

  protected subscribeToNewsletter() {
    if (this.form.valid) {
      this.isBusy.set(true);
      this.isSubscribed.set(false);

      const { email } = this.form.getRawValue();

      this.#footerService
        .subscribeToNewsletter(email)
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          finalize(() => this.isBusy.set(false)),
        )
        .subscribe({
          next: () => {
            this.isSubscribed.set(true);
            this.form.reset();
          },
          error: ({ errors }) => {
            if (errors) {
              const errorMessages = Object.values(errors).flat().join(' ');
              this.#snackBar.error(`An error occurred subscribing to the newsletters: ${errorMessages}`);
            }
          },
        });
    }
  }
}
