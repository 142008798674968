import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { Confirmation } from './confirmation-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  protected data: Confirmation = inject(DIALOG_DATA);
  #dialogRef = inject(DialogRef);

  closeDialog(response: boolean) {
    this.#dialogRef.close(response);
  }
}
