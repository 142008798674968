<footer>
  <div class="bg-primary">
    <div class="px-4 py-16 flex flex-col items-center gap-8 border-b-2 border-black">
      <img src="assets/images/icon.svg" class="h-16" alt="Bongo's Bingo" />

      <div
        class="flex flex-col md:flex-row items-center gap-8 lg:gap-14 [&>a]:text-2xl [&>a]:font-bold [&>a]:text-white"
      >
        <a [routerLink]="['/', 'events']" aria-label="Search for an event">Events</a>
        <a [routerLink]="['/']" aria-label="Who are the Hosts and Dancers?">Hosts & Dancers</a>
        <a [routerLink]="['/']" aria-label="What to expect at Bongo's Bingo">What Is Bongo's?</a>
        <a [routerLink]="['/']" aria-label="Frequently asked questions">Faq's</a>
        <a [routerLink]="['/']" aria-label="Get in touch with Bongo's Bingo">Contact</a>
        <a [routerLink]="['/']" aria-label="Recruitment">Recruitment</a>
      </div>
    </div>

    <div class="px-4 pt-2 pb-8 border-b-2 border-black">
      <app-newsletter></app-newsletter>
    </div>

    <div class="px-4 py-16 relative overflow-hidden">
      <img
        class="absolute w-52 top-[-1.5rem] right-[70vw] md:right-[80vw] lg:right-[75vw] 2xl:right-[70vw] xl:top-auto z-0"
        src="assets/images/unicorn.svg"
        alt=""
      />
      <img
        class="absolute w-52 top-72 left-[60vw] md:left-[80vw] lg:left-[75vw] 2xl:left-[70vw] xl:top-auto z-0"
        src="assets/images/duck.svg"
        alt="Duck"
      />

      <div class="flex flex-col items-center">
        <div id="app-banner" class="w-full max-w-3xl hidden xl:block z-10">
          <p class="text-3xl text-white font-normal w-[30rem] mx-auto my-4 text-center">
            Scan to download App on the Playstore and Appstore.
          </p>

          <div class="lg:flex xl:flex-row xl:gap-8 justify-center w-full max-w-3xl hidden md:block z-10">
            <div class="qr-code-box" id="android">
              <a href="" target="_blank" class="mt-4">
                <div class="h-40 w-[160px] bg-white flex-shrink-0">
                  <img
                    src="assets/images/qr-code.png"
                    alt="A QR code you can scan to download the Bongo Bingos app on the Google Play Store"
                  />
                </div>
                <div class="mt-4 flex justify-center">
                  <img class="w-10 h-10" src="assets/images/logo-playstore.png" alt="Google Play" />
                </div>
              </a>
            </div>

            <div class="qr-code-box" id="ios">
              <a href="" target="_blank" class="mt-4">
                <div class="h-40 w-[160px] bg-white flex-shrink-0">
                  <img
                    src="assets/images/qr-code.png"
                    alt="A QR code you can scan to download the Bongo Bingos app on the App Store"
                  />
                </div>
                <div class="mt-4 flex justify-center">
                  <img class="w-10 h-10" src="assets/images/logo-appstore.png" alt="App Store" />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div id="app-links" class="xl:hidden flex flex-col gap-4 py-8">
          <h2 class="text-3xl font-black text-center text-white">Download Our App</h2>

          <a href="" target="_blank">
            <img
              src="assets/images/googleplay.svg"
              alt="A QR code you can scan to download the Bongo Bingos app on the Google Play Store"
            />
          </a>
          <a href="" target="_blank">
            <img
              src="assets/images/appstore.svg"
              alt="A QR code you can scan to download the Bongo Bingos app on the App Store"
            />
          </a>
        </div>

        <div class="flex flex-col items-center mt-[8rem] lg:mt-[11rem] xl:mt-0 xl:mb-10">
          <div class="flex xl:flex-row flex-col items-center gap-6 xl:gap-14 mt-20 lg:mt-10 mb-10">
            <a href="https://www.gambleaware.org/" target="_blank"
              ><img src="assets/images/gamble-aware.png" alt="Gamble Aware"
            /></a>
            <a href="https://www.drinkaware.co.uk/" target="_blank"
              ><img src="assets/images/drink-aware.png" alt="Drink Aware"
            /></a>
            <a href="https://www.gamblingcommission.gov.uk/" target="_blank"
              ><img src="assets/images/gambling-commission.png" alt="Gambling Commission"
            /></a>
          </div>

          <nav
            class="flex flex-col xl:flex-row flex-wrap items-center justify-center gap-14 lg:gap-8 xl:gap-12 [&>a]:font-normal [&>a]:text-xl [&>a]:text-white [&>a]:text-center"
          >
            <a [routerLink]="['/']" class="cursor-pointer">How to Play</a>
            <a [routerLink]="['/terms-and-conditions']" class="cursor-pointer">Terms & Conditions</a>
            <a [routerLink]="['/']" class="cursor-pointer">Corporation Terms & Conditions</a>
            <a [routerLink]="['/']" class="cursor-pointer">Sales & Refunds</a>
            <a [routerLink]="['/']" class="cursor-pointer">Responsible Play</a>
            <a [routerLink]="['/']" class="cursor-pointer">Self Exclusion</a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</footer>
