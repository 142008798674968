import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { Component, OnInit, TemplateRef, computed, inject, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs';
import { NavigationEnd, Router, RouterLink } from '@angular/router';

import { AuthService } from '@features/auth';
import { BasketService } from '@features/checkout';
import { CustomPagesService } from '@features/custom-pages';

import { NavigationDrawerComponent } from '../navigation-drawer';
import { CdkMenuModule } from '@angular/cdk/menu';

@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, DialogModule, CdkMenuModule],
})
export class MastheadComponent implements OnInit {
  protected auth = inject(AuthService);
  protected basket = inject(BasketService);
  #router = inject(Router);
  #dialog = inject(Dialog);
  #pages = inject(CustomPagesService);

  protected navigation = computed(() => this.#pages.navigation().menu_items);

  protected drawer = viewChild.required<TemplateRef<unknown>>('drawer');
  protected redirectUrl = signal<string>('/');

  ngOnInit() {
    this.#router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter((event) => !event.url.includes('?redirect=')),
      )
      .subscribe((event) => {
        return this.redirectUrl.set(event.urlAfterRedirects);
      });
  }

  protected openDrawer() {
    this.#dialog.open<void, boolean>(NavigationDrawerComponent, {
      height: '100%',
      width: '100%',
    });
  }
}
