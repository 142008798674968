import { Component, effect, inject, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { FooterComponent } from '@components/footer';
import { MastheadComponent } from '@components/masthead';
import { SnackBarComponent } from '@components/snack-bar';
import { AuthService } from '@features/auth';
import { BasketService } from '@features/checkout';

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [],
  imports: [RouterOutlet, MastheadComponent, FooterComponent, SnackBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  #auth = inject(AuthService);
  #basket = inject(BasketService);

  #unsubscribe = new Subject<void>();

  constructor() {
    effect(() => {
      const user = this.#auth.user();

      this.#unsubscribe.next();

      if (user?.account_active) {
        this.#basket.getBasket().pipe(takeUntil(this.#unsubscribe)).subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.#unsubscribe.next();
    this.#unsubscribe.complete();
  }
}
