<div class="max-w-sm w-full bg-[#F9F9F9] rounded-3xl shadow-md p-4">
  <div class="px-4 py-2">
    <h1 class="text-center text-xl font-semibold text-dialog-title">{{ data.title }}</h1>
  </div>

  <div class="px-4 py-6">
    <p class="text-center text-dialog-description">{{ data.description }}</p>
  </div>

  <div class="px-4 py-3 flex flex-col gap-2">
    <button class="primary-button" (click)="closeDialog(true)">
      {{ data.confirmButton }}
    </button>
    <button class="link-button" (click)="closeDialog(false)">
      {{ data.cancelButton }}
    </button>
  </div>
</div>
